import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"

const TermsPage = () => (
  <Layout>
    <Header />
    <section className="page">
      <div className="container">
        <h2>
          Obecné podmínky užívání aplikace Živlovka a zásady zpracování osobních
          údajů
        </h2>
        <p>
          Provozovatelem mobilní aplikace Živlovka (dále jen jako „Aplikace“) je
          společnost AURIDEA, s.r.o., se sídlem Čestice 192, 387 19, Čestice,
          IČO: 281 33 196, zapsaná v obchodním rejstříku vedeném u Krajského
          soudu v Českých Budějovicích, spisová značka C 19 244 (dále jen jako
          „Provozovatel“).
        </p>
        <p>
          Provozovatel a uživatel Aplikace (dále jen jako „Uživatel“) uzavírají
          smluvní ujednání, na jehož základě Provozovatel poskytuje Uživateli
          možnost užívat prostřednictvím mobilního zařízení Aplikaci. Předmětem
          Aplikace je hra Živlovka, jejíž princip je založen na práci na
          interakci Uživatele s obsahem Aplikace.
        </p>
        <p>
          Užívání Aplikace podléhá níže specifikovaným obecným podmínkám užívání
          (dále jen jako „Podmínky“) a Uživatel tím, že pokračuje v užívání
          Aplikace, prohlašuje, že se s obsahem Podmínek seznámil a souhlasí s
          nimi. Uživatel se také seznámil s informacemi o zpracování osobních
          údajů, které jsou součástí těchto Podmínek.
        </p>
        <p>
          Ex magna esse id sint. Enim aliquip veniam irure ullamco esse nostrud
          sunt nostrud nostrud. Minim irure aute deserunt minim eiusmod. Elit
          nulla id deserunt tempor ex eiusmod excepteur ipsum proident
          incididunt elit proident nostrud. Eu dolor voluptate enim qui amet
          Lorem nisi minim. Labore commodo pariatur ipsum proident do occaecat.
        </p>
        <h3>Podmínky užívání aplikace</h3>
        <p>
          Provozovatel je provozovatelem Aplikace, přičemž ji provozuje v rámci
          své obchodní nebo jiné podnikatelské činnosti. Uživatelem může být
          fyzická i právnická osoba. Užívání aplikace není podmíněno registrací,
          je ale zpoplatněno po uplynutí období 7 kalendářních dnů od
          registrace.
        </p>
        <p>
          Uživatel stažením a užíváním Aplikace prohlašuje, že je starší 15 let.
          Provozovatel a Uživatel uzavírají prostřednictvím Aplikace smlouvu dle
          občanského zákoníku (tj. zákona č. 89/2012 Sb., občanský zákoník) a
          dalších relevantních právních předpisů České republiky. Smlouva je
          uzavřena okamžikem, kdy Uživatel dokončí instalaci Aplikace a zahájí
          její užívání.
        </p>
        <p>
          Prostřednictvím Aplikace je Uživateli ze strany Provozovatele
          poskytována služba, jejímž obsahem je hra Živlovka. Princip této hry
          je založen na interakci Uživatele a grafického ztvárnění živlů.
          Aplikace také umožňuje archivaci jednotlivých her realizovaných
          Uživatelem. Aplikace je uživatelům poskytována bezplatně po dobu 7
          kalendářních dnů od její instalace. Následně je její užívání
          zpoplatněno jednorázovým poplatkem prostřednictvím platebních služeb
          třetích stran. Do doby, než je jednorázový poplatek uhrazen, dojde
          k omezení funkcionalit poskytovaných v rámci Aplikace. Po zaplacení
          jednorázového poplatku může Uživatel užívat Aplikaci po neomezeně
          dlouhou dobu.
        </p>
        <p>
          Provozovatel si vyhrazuje právo v budoucnu nabídnout prostřednictvím
          Aplikace další služby a tyto zpoplatnit. V rámci aplikace také může
          Provozovatel zobrazovat reklamy či nabídky na své produkty či služby,
          anebo na produkty a služby třetích stran.
        </p>
        <p>
          Uživatel se nesmí žádným způsobem pokoušet prolomit zabezpečení
          Aplikace, ohrozit její provoz nebo Aplikaci jakýmkoliv způsobem
          zneužívat. V případě, že se Uživatel dopustí jednání, které je v
          rozporu s těmito obchodními podmínkami, může být smlouva mezi ním a
          Provozovatelem jednostranně ukončena, v důsledku čehož mu bude
          znemožněn přístup do Aplikace.
        </p>
        <p>
          Provozovatel neposkytuje uživatelům záruku za nepřetržitý provoz
          Aplikace, ani na dostupnost jejího obsahu. Provozovatel si vyhrazuje
          právo na úpravu či smazání obsahu Aplikace či jeho části, a to bez
          předběžného varování Uživatele. Uživatel je také oprávněn přerušit či
          zcela ukončit provoz Aplikace, a to i ve vztahu k uživatelům, kteří
          zaplatili jednorázový poplatek. Jakékoli podněty, upozornění a
          stížnosti na obsah Aplikace lze směřovat Provozovateli prostřednictvím
          kontaktních údajů uvedených na internetových stránkách www.zivlovka.cz
        </p>
        <p>
          Smlouva uzavřená podle těchto obchodních podmínek se řídí právním
          řádem České republiky. Provozovatel si vyhrazuje právo na změnu
          poskytování kterékoliv ze služeb, a to i bez předchozího upozornění.
          Provozovatel si dále vyhrazuje právo na pozdější změny těchto
          obchodních podmínek provedené v souladu s příslušnými právními
          předpisy. Uživatel má možnost smlouvu vypovědět oznámením adresovaným
          Provozovateli písemně, emailem či jiným vhodným způsobem, a tím, že
          Aplikaci odinstaluje. Nevzniká mu ovšem nárok na vrácení případně
          zaplaceného jednorázového poplatku.
        </p>
        <p>
          Uživatel uzavřením této smlouvy požaduje ve smyslu zákonných
          ustanovení o závazcích ze smluv uzavíraných distančním způsobem, aby
          Provozovatel s poskytováním služeb podle smlouvy započal okamžitě po
          uzavření smlouvy. Uživatel může od smlouvy odstoupit do 14 dnů od
          jejího uzavření odstoupením od smlouvy adresovaným Provozovateli.
          Lhůta je zachována, pokud během ní Uživatel oznámení o odstoupení
          odešle. Pokud v souvislosti s poskytováním služeb přijal Provozovatel
          od Uživatele finanční plnění, toto plnění případně jeho poměrnou část
          v případě, že s poskytováním placených služeb bylo již započato, vrátí
          Uživateli, a to nejpozději do 14 dnů od odstoupení od smlouvy. Pokud
          není možné využít stejný platební způsob, kterým Uživatel uhradil
          příslušnou platbu, vrátí Provozovatel plnění jiným způsobem dle
          specifikace Uživatele. Pokud by Uživatel výslovně vyžadoval vrácení
          finančního plnění způsobem, s jehož využitím budou spojeny zvýšené
          náklady, jdou tyto náklady k tíži Uživatele.
        </p>
        <p>
          Pokud by mezi Uživatelem a Provozovatelem došlo ke spotřebitelskému
          sporu, je Uživatel oprávněn řešit tento spor mimosoudně. Subjektem
          mimosoudního řešení sporu podle zákona č. 634/1992 Sb., o ochraně
          spotřebitele, je Česká obchodní inspekce se sídlem Štěpánská 567/15,
          120 00 Praha 2, IČ: 000 20 869. Veškeré informace k mimosoudnímu
          řešení jsou uvedeny na internetových stránkách České obchodní inspekce
          www.coi.cz. Zahájit alternativní řešení sporu je možné rovněž
          prostřednictvím on-line formuláře na stránkách
          webgate.ec.europa.eu/odr.
        </p>
        <h3>Informace o zpracování osobních údajů</h3>
        <p>
          Provozovatel tímto v souladu s článkem 13 a násl. Nařízení Evropského
          parlamentu a Rady (EU) 2016/679, ze dne 27. dubna 2016 o ochraně
          fyzických osob v souvislosti se zpracováním osobních údajů a o volném
          pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o
          ochraně osobních údajů) (dále jen „Nařízení“), informuje všechny
          subjekty údajů, kterých se to týká, o osobních údajích zpracovávaných
          Provozovatelem.
        </p>
        <p>
          Správcem osobních údajů je společnost AURIDEA, s.r.o., se sídlem
          Čestice 192, 387 19, Čestice, IČO: 281 33 196, zapsaná v obchodním
          rejstříku vedeném u Krajského soudu v Českých Budějovicích, spisová
          značka C 19 244. Aktuální kontaktní e-mail správce je uveden na
          internetové stránce www.zivlovka.cz. Společnost nemá povinnost a
          dobrovolně nezřizuje pověřence pro ochranu osobních údajů.
        </p>
        <p>
          Provozovatel při provozování Aplikace žádným způsobem neshromažďuje a
          nezpracovává osobní údaje uživatelů Aplikace. Aplikace je poskytována
          bez potřeby registrace a v rámci Aplikace či užíváním Aplikace není
          udělen souhlas se zpracováním osobních údajů. Poskytnutí osobních
          údajů Provozovateli není zákonným ani smluvním požadavkem.
        </p>
        <p>
          V souvislosti s provozováním Aplikace nicméně mohou být Provozovateli
          poskytnuty osobní údaje konkrétního uživatele jiným způsobem, než
          prostřednictvím Aplikace (např. emailem či písemně). V takovém případě
          jsou osobní údaje užívány výlučně v souvislosti s naplňováním
          smluvního vztahu mezi uživatelem Aplikace a Provozovatelem a tyto
          osobní údaje budou použity výlučně v souladu s obecně závaznými
          právními předpisy.
        </p>
        <p>
          Uživatelé Aplikace jsou oprávněni kdykoli podat stížnost u dozorového
          orgánu Provozovatele. Uživatelé mají právo požadovat nápravu situace,
          která je v rozporu s právními předpisy, zejména formou pozastavení
          nebo zastavení nakládání s osobními údaji, jejich opravou, doplněním
          či odstraněním, kontaktovat Úřad pro ochranu osobních údajů v případě
          výše uvedeného podezření nebo odmítnutí zajistit nápravu situace,
          která je v rozporu s právními předpisy. Stížnost je možno podat u
          dozorového orgánu, tj. Úřadu pro ochranu osobních údajů, se sídlem
          Pplk. Sochora 27, 170 00 Praha 7, www.uoou.cz, e-mail: posta@uoou.cz.
        </p>
      </div>
    </section>
  </Layout>
)

export default TermsPage
